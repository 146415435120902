import { makeStyles } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import PropTypes from 'prop-types';

import { colors } from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  button: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '1rem',
    color: colors.dark_purple,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '1rem',
      cursor: 'pointer',
    },
  },
}));

GoBackButton.propTypes = {
  onClick: PropTypes.func,
};

export function GoBackButton({ onClick }) {
  const classes = useStyles();

  return (
    <div className={classes.button} onClick={onClick}>
      <ArrowBackIosRoundedIcon />
      <span>Voltar</span>
    </div>
  );
}
