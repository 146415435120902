export const colors = {
  text: '#d2cfd1',
  background: '#F1F1F1',

  light_gray: '#3F4246',
  gray: '#d2cfd1',
  gray_dark: '#3F4246',
  black: '#090e15',

  button_gray: '#535860',
  button_light_gray: '#E6E7E8',

  blue: '#00b6ee',
  blue_dark: '#003e75',

  green: '#3fb758',
  green_dark: '#039448',
  green_light: '#85c991',

  red: '#f36e54',
  red_dark: '#f05539',
  red_light: '#f69786',

  light_purple: '#8692C9',
  light_purple_shade: '#6b74a0',
  dark_purple: '#4559A8',

  // Design System
  primary_20: '#ADDCEE',
  primary_40: '#00B5ED',
  primary_60: '#0067AE',
  primary_80: '#003E75',

  secondary_20: '#9BD9E5',
  secondary_40: '#3DC2D4',
  secondary_60: '#15B1C6',
  secondary_80: '#0597AC',

  tertiary_20: '#8692C9',
  tertiary_40: '#5B6EB4',
  tertiary_60: '#4559A8',

  white: '#FFFFFF',
  basic_10: '#F3F3F3',
  basic_20: '#E9E9E9',
  basic_30: '#D4D0D1',
  basic_40: '#B9B5B6',
  basic_50: '#797C81',
  basic_60: '#535860',
  basic_70: '#222832',

  positive_10: '#BCE2C3',
  positive_20: '#4FB961',
  positive_40: '#0B9748',
  neutral_10: '#FBD394',
  neutral_20: '#FFC05B',
  neutral_40: '#FFAD29',
  negative_10: '#F69787',
  negative_20: '#F3755C',
  negative_40: '#F15639',
};
