import { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { getBlocks, getBlockReceipts } from '../../api/index';

import Loading from '../../components/Loading';
import Toast from '../../components/Toast';

import { sortData } from '../../utils/sortData';

import { colors } from '../../styles/colors';

Receipts.propTypes = {
  handler: PropTypes.func,
};

export default function Receipts(props) {
  const toastRef = useRef();
  const [block, setBlock] = useState('');
  const [blocks, setBlocks] = useState('');
  const [receipts, setReceipts] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalReceipts, setTotalReceipts] = useState(0);

  const handleGetBlockReceipts = async (
    block_id,
    newPage = 0,
    newRowsPerPage = 10,
  ) => {
    const offset = newPage + 1;
    const limit = newRowsPerPage;
    const response = await getBlockReceipts(block_id, limit, offset);
    if (response.status === 200) {
      setReceipts(response.data.result.receipts);
      setTotalReceipts(response.data.result.total);
    } else {
      toastRef.current.handleOpen(
        'Erro ao recuperar os recibos do bloco.',
        'error',
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleGetBlockReceipts(block.id, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = rowsPerPageInput => {
    const newRowsPerPage = parseInt(rowsPerPageInput, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    handleGetBlockReceipts(block.id, 0, newRowsPerPage);
  };

  const handleChangeBlock = blockInput => {
    setBlock(blockInput);
    handleGetBlockReceipts(blockInput.id);
  };

  useEffect(() => {
    props.handler('Recibos');
    const handleGetBlocks = async () => {
      const blocksResponse = await getBlocks();
      if (blocksResponse.status === 200) {
        const sortedBlocks = sortData(blocksResponse.data, 'name');
        setBlocks(sortedBlocks);
      } else {
        toastRef.current.handleOpen('Erro ao recuperar blocos.', 'error');
      }
    };
    handleGetBlocks();
  }, [props]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Toast ref={toastRef} />

      <div>
        <Typography
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: colors.basic_70,
          }}
          variant="h5"
          gutterBottom>
          Recibos
        </Typography>
      </div>

      <FormControl style={{ minWidth: 120 }}>
        {blocks !== '' ? (
          <>
            <InputLabel id="demo-simple-select-label">Blocos</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={block}
              onChange={event => handleChangeBlock(event.target.value)}>
              {blocks.map(block => (
                <MenuItem key={block.id} value={block}>
                  {block.name}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          <div style={{ marginLeft: '40px' }}>
            <Loading />
          </div>
        )}
      </FormControl>

      <div style={{ marginTop: '15px' }}>
        {receipts !== '' ? (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      color: colors.tertiary_40,
                      fontSize: '1.2rem',
                    }}>
                    Nome do entregador
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      color: colors.tertiary_40,
                      fontSize: '1.2rem',
                    }}>
                    Telefone
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      color: colors.tertiary_40,
                      fontSize: '1.2rem',
                    }}>
                    Data da entrega
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      color: colors.tertiary_40,
                      fontSize: '1.2rem',
                    }}>
                    Porta
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 600,
                      color: colors.tertiary_40,
                      fontSize: '1.2rem',
                    }}>
                    Apartamento
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receipts.map(receipt => (
                  <TableRow key={receipt.id}>
                    <TableCell>{receipt.delivery_name}</TableCell>
                    <TableCell>{receipt.delivery_phone_number}</TableCell>
                    <TableCell>
                      {new Date(receipt.delivery_date).toLocaleString('pt-br')}
                    </TableCell>
                    <TableCell>{receipt.lock_number}</TableCell>
                    <TableCell>{receipt.habitation_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalReceipts}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={event =>
                handleChangeRowsPerPage(event.target.value)
              }
              labelRowsPerPage={'Recibos por página'}
            />
          </TableContainer>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
