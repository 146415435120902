import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { getAdministered, loginAsCompany } from '../../api/administrator';

import Toast from '../../components/Toast';

AdministeredCompanies.propTypes = {
  handler: PropTypes.func,
};

export default function AdministeredCompanies(props) {
  const toastRef = useRef();
  const [companies, setCompanies] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = rowsPerPageInput => {
    setRowsPerPage(parseInt(rowsPerPageInput, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const openInNewTab = (token, company) => {
    localStorage.setItem('token', JSON.stringify('Bearer ' + token));
    localStorage.setItem('type', JSON.stringify('company'));
    localStorage.setItem('condoCompany', JSON.stringify(company));
    localStorage.setItem('adminType', 'Administrator');
    window.open('/', '_blank', 'noopener,noreferrer');
  };

  const loginCompany = company => {
    loginAsCompany(company.id).then(response => {
      if (response.status === 200) {
        openInNewTab(response.result, company);
      } else {
        toastRef.current.handleOpen('Erro ao acessar a empresa.', 'error');
      }
    });
  };

  useEffect(() => {
    props.handler('Empresas Administradas');
    const fetchAdministered = async () => {
      await getAdministered().then(response => {
        setCompanies(response.result);
      });
    };
    fetchAdministered();
  }, [props]);

  return (
    <div
      style={{
        backgroundColor: 'white',
        minHeight: '300px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '10px',
      }}
    >
      <Toast ref={toastRef} />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies !== '' ? (
              companies
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(company => (
                  <TableRow
                    key={company.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell align="center">{company.name}</TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => {
                          loginCompany(company);
                        }}
                        variant="contained"
                        style={{
                          backgroundColor: '#2196f3 ',
                          color: 'white',
                          margin: '10px',
                        }}
                      >
                        Login
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={companies.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={event =>
            handleChangeRowsPerPage(event.target.value)
          }
          labelRowsPerPage={'Senhas por página'}
        />
      </TableContainer>
    </div>
  );
}
