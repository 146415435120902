import { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
} from '@material-ui/core';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';

import { loginAsCondo, getAllCondos } from '../../api/administrator';

import Toast from '../../components/Toast';
import { colors } from '../../styles/colors';
import { changeCondoStatus } from '../../api';

Condos.propTypes = {
  handler: PropTypes.func,
};

export default function Condos(props) {
  const toastRef = useRef();
  const [condos, setCondos] = useState([]);
  const [checkedCondo, setCheckedCondo] = useState({});
  const [newCheckedCondoStatus, setNewCheckedCondoStatus] = useState('active');
  const [countCondos, setCountCondos] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [direction, setDirection] = useState('ASC');
  const [param, setParam] = useState('id');
  const [condoStatusFilter, setCondoStatusFilter] = useState('');
  const [openCondoStatusDialog, setOpenCondoStatusDialog] = useState(false);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const openInNewTab = (token, condo) => {
    localStorage.setItem('token', JSON.stringify('Bearer ' + token));
    localStorage.setItem('type', JSON.stringify('condo'));
    localStorage.setItem('condoCompany', JSON.stringify(condo));
    localStorage.setItem('adminType', 'Lockin');
    window.open('/', '_blank', 'noopener,noreferrer');
  };

  const handleCloseCondoStatusDialog = () => {
    setOpenCondoStatusDialog(false);
  };

  const loginCondo = condo => {
    loginAsCondo(condo.id).then(response => {
      if (response.status === 200) {
        openInNewTab(response.result, condo);
      } else {
        toastRef.current.handleOpen('Erro ao acessar o condomínio.', 'error');
      }
    });
  };

  const handleOrdering = paramInput => {
    setParam(paramInput);
    direction === 'ASC' ? setDirection('DESC') : setDirection('ASC');
  };

  const handleCondoStatusFilterChange = value => {
    setCondoStatusFilter(value);
  };

  const handleChangeCondoStatus = async () => {
    const response = await changeCondoStatus(
      checkedCondo.id,
      newCheckedCondoStatus,
    );
    if (response.status === 200) {
      toastRef.current.handleOpen(
        'Status do condomínio alterado com sucesso!',
        'success',
      );
      getAllCondos('id ASC', 1, 10).then(response => {
        setCondos(response.result.condos);
        setCountCondos(response.result.total);
      });
    } else {
      toastRef.current.handleOpen(
        'Erro ao alterar o status do condomínio.',
        'error',
      );
    }
    setOpenCondoStatusDialog(false);
  };

  const handleCondoActivation = async (condo, checked) => {
    setCheckedCondo(condo);
    const newStatus = checked === true ? 'active' : 'inactive';
    setNewCheckedCondoStatus(newStatus);
    setOpenCondoStatusDialog(true);
  };

  useEffect(() => {
    async function fetchCondos() {
      try {
        let response;
        if (condoStatusFilter.length > 0) {
          response = await getAllCondos(
            `${param} ${direction}`,
            page + 1,
            rowsPerPage,
            condoStatusFilter,
          );
        } else {
          response = await getAllCondos(
            `${param} ${direction}`,
            page + 1,
            rowsPerPage,
          );
        }
        setCondos(response.result.condos);
        setCountCondos(response.result.total);
      } catch (error) {
        console.error('Error fetching condos:', error);
      }
    }
    fetchCondos();
  }, [page, rowsPerPage, direction, param, condoStatusFilter]);

  useEffect(() => {
    props.handler('Todos Condomínios');
    getAllCondos('id ASC', 1, 10).then(response => {
      setCondos(response.result.condos);
      setCountCondos(response.result.total);
    });
  }, [props]);

  return (
    <div
      style={{
        backgroundColor: 'white',
        minHeight: '300px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '10px',
      }}>
      <Toast ref={toastRef} />

      <Dialog
        open={openCondoStatusDialog}
        onClose={handleCloseCondoStatusDialog}>
        <DialogTitle>{`Alterar status do condomínio ${checkedCondo.name}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja mesmo alterar o status do condomínio?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ fontWeight: 600, color: colors.basic_40 }}
            onClick={handleCloseCondoStatusDialog}>
            Cancelar
          </Button>
          <Button
            style={{ fontWeight: 600, color: colors.tertiary_40 }}
            onClick={() => handleChangeCondoStatus()}
            autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ alignSelf: 'flex-start' }}>
        <FormControl sx={{ m: 1, minWidth: 220 }}>
          <InputLabel id="condo-status-select">
            Status dos condomínios
          </InputLabel>
          <Select
            labelId="condo-status-select"
            id="condo-select-autowidth"
            autoWidth
            value={condoStatusFilter}
            onChange={event => {
              handleCondoStatusFilterChange(event.target.value);
            }}
            label="Status dos condomínios">
            <MenuItem style={{ marginTop: '4px', height: '30px' }} value="" />
            <MenuItem value="active">Ativos</MenuItem>
            <MenuItem value="inactive">Inativos</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>
                ATIVO
              </TableCell>
              <TableCell align="center">
                <Button
                  type="button"
                  onClick={() => handleOrdering('id')}
                  style={{ fontWeight: 'bold' }}>
                  ID
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button
                  type="button"
                  onClick={() => handleOrdering('name')}
                  style={{ fontWeight: 'bold' }}>
                  Nome
                </Button>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {condos !== '' ? (
              condos.map(condo => (
                <TableRow
                  key={condo.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}>
                  <TableCell align="center">
                    <Checkbox
                      checked={condo.status === 'active'}
                      onChange={event =>
                        handleCondoActivation(condo, event.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell align="center">{condo.id}</TableCell>
                  <TableCell align="center">{condo.name}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => {
                        loginCondo(condo);
                      }}
                      variant="contained"
                      style={{
                        backgroundColor: '#2196f3 ',
                        color: 'white',
                        margin: '10px',
                      }}>
                      Login
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={countCondos}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={'Condomínios por página'}
        />
      </TableContainer>
    </div>
  );
}
