import { makeStyles } from '@material-ui/core';
import { colors } from './styles/colors';

const drawerWidth = '305px';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    background: colors.background,
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    color: colors.white,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth} - ${2 * theme.spacing(3)}px)`,
      right: theme.spacing(3),
      marginTop: '50px',
      borderRadius: '28px',
      boxShadow: 'none',
      background: colors.white,
      color: '#797C81',
    },
  },
  appBarBack: {
    [theme.breakpoints.up('sm')]: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '70px',
      width: '100%',
      zIndex: 2,
      background: colors.background,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: colors.background,
    border: 'none',
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuText: {
    textDecoration: 'none',
    color: 'black',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  patchNotesContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
}));

export { useStyles };
