import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { AverageOccupancy } from './components/AverageOccupancy';
import { TotalOpenings } from './components/TotalOpenings';
import { TotalDeliveries } from './components/TotalDeliveries';

import styles from './styles.module.scss';

Dashboard.propTypes = {
  handler: PropTypes.func,
};

export default function Dashboard(props) {
  useEffect(() => {
    props.handler('Visão Geral');
  }, [props]);

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.cardsContainer}>
        <TotalDeliveries />
        <AverageOccupancy />
        <TotalOpenings />
      </div>
    </div>
  );
}
