import axios from 'axios';

const baseUrl = process.env.REACT_APP_LOOPBOX_API_URL;

export const getAdministered = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/administrator/administered', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const loginAsCondo = async condo_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v2/administrator/login/condo',
      { condo_id: condo_id },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
      },
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const loginAsCompany = async company_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v2/administrator/login/company',
      { company_id: company_id },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
      },
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const getAllCondos = async (order_string, offset, limit, status) => {
  const token = sessionStorage.getItem('token');

  try {
    const response = await axios.get(baseUrl + '/v2/condos', {
      params: {
        order_string: order_string,
        offset: offset,
        limit: limit,
        status: status,
      },
      headers: { Authorization: token.replace(/['"]+/g, '') },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const getAllCompanies = async (order_string, offset, limit) => {
  const token = sessionStorage.getItem('token');

  try {
    const response = await axios.get(baseUrl + '/v2/companies', {
      params: {
        order_string: order_string,
        offset: offset,
        limit: limit,
      },
      headers: { Authorization: token.replace(/['"]+/g, '') },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};
