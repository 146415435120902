import axios from 'axios';

const baseUrl = process.env.REACT_APP_LOOPBOX_API_URL;

export const getCondoOccupancyPercentage = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/condo/occupancy/percentage', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const getTotalDeliveries = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/condo/delivery/total', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const getTotalOpenings = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/condo/opening/total', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};
