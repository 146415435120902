import { Link } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import MenuText from '../Typography/MenuText';
import { colors } from '../../styles/colors';

const useStyles = makeStyles(() => ({
  menuButton: {
    textDecoration: 'none',
    color: colors.light_gray,
  },
  menuText: prop => ({
    paddingTop: '1rem',
    paddingBottom: '1rem',

    ...(prop.selected && {
      background:
        'linear-gradient(269.93deg, rgba(173, 220, 238, 0.378) -65.52%, rgba(173, 220, 238, 0) 139.16%)',
      borderRight: '6px solid #ADDCEE',
    }),
  }),
  icon: {
    minWidth: '45px',
  },
}));

MenuButton.propTypes = {
  selected: PropTypes.bool,
  text: PropTypes.string,
  icon: PropTypes.element,
  route: PropTypes.string,
};

export default function MenuButton({ selected, text, icon, route }) {
  const classes = useStyles({ selected });

  return (
    <Link to={route} className={classes.menuButton}>
      <ListItem button key={text} className={classes.menuText}>
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        <ListItemText>
          <MenuText text={text.toUpperCase()} />
        </ListItemText>
      </ListItem>
    </Link>
  );
}
