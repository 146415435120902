import { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { IconButton, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';

import { getCondoControllers, getLockerLogs } from '../../api/index';

import Loading from '../../components/Loading';
import Toast from '../../components/Toast';
import MultipleSelect from '../../components/MultipleSelect';

import { capitalize } from '../../utils/capitalize';
import { normalizeControllers } from '../../utils/normalizeControllers';
import { sortData } from '../../utils/sortData';

import { colors } from '../../styles/colors';

Logs.propTypes = {
  handler: PropTypes.func,
};

export default function Logs(props) {
  const toastRef = useRef();
  const [controller, setController] = useState('');
  const [controllers, setControllers] = useState('');
  const [logs, setLogs] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalLogs, setTotalLogs] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const normalizeCategories = categories => {
    const normalizedCategories = categories.map(category =>
      category.toLowerCase(),
    );
    return normalizedCategories;
  };

  const handleGetLockerLogs = async (
    newPage = 0,
    newRowsPerPage = 10,
    selectedCategories = [],
  ) => {
    const offset = newPage + 1;
    const limit = newRowsPerPage;
    await getLockerLogs(
      controller.controller_id,
      limit,
      offset,
      normalizeCategories(selectedCategories),
    )
      .then(response => {
        setLogs(response.data.result.logs);
        setTotalLogs(response.data.result.total);
      })
      .catch(() => {
        toastRef.current.handleOpen(
          'Erro ao recuperar histórico de uso.',
          'error',
        );
      });
  };

  const handleChangeController = async controllerInput => {
    setController(controllerInput);
    setPage(0);
    setRowsPerPage(10);
    setSelectedCategories([]);
    await getLockerLogs(controllerInput.controller_id, 10, 0, [])
      .then(response => {
        setLogs(response.data.result.logs);
        setTotalLogs(response.data.result.total);
      })
      .catch(() => {
        toastRef.current.handleOpen(
          'Erro ao recuperar histórico de uso.',
          'error',
        );
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleGetLockerLogs(newPage, rowsPerPage, selectedCategories);
  };

  const handleChangeRowsPerPage = rowsPerPageInput => {
    setPage(0);
    const newRowsPerPage = parseInt(rowsPerPageInput, 10);
    setRowsPerPage(newRowsPerPage);
    handleGetLockerLogs(0, newRowsPerPage, selectedCategories);
  };

  useEffect(() => {
    props.handler('Histórico de uso');
    const fetchCondoControllers = async () => {
      const controllers = await getCondoControllers();
      if (controllers.status === 200) {
        const normalizedControllers = normalizeControllers(controllers.data);
        const sortedControllers = sortData(
          normalizedControllers,
          'controller_name',
        );
        setControllers(sortedControllers);
      } else {
        toastRef.current.handleOpen('Erro ao recuperar armários.', 'error');
      }
    };
    fetchCondoControllers();
  }, [props]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Toast ref={toastRef} />

      <div>
        <Typography
          style={{ fontFamily: 'Montserrat', fontWeight: 600 }}
          variant="h5"
          gutterBottom>
          Histórico de uso
        </Typography>
      </div>

      <FormControl style={{ minWidth: 120 }}>
        {controllers !== '' ? (
          <>
            <InputLabel id="demo-simple-select-label">Armários</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={controller}
              onChange={event => handleChangeController(event.target.value)}>
              {controllers.map(controller => (
                <MenuItem key={controller.controller_id} value={controller}>
                  {controller.controller_name}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          <div style={{ marginLeft: '40px' }}>
            <Loading />
          </div>
        )}
      </FormControl>

      <div style={{ marginTop: '40px' }}>
        {logs !== '' ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MultipleSelect
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                handleGetLockerLogs={handleGetLockerLogs}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
              />
              <Tooltip
                title={
                  <ul>
                    <li>
                      <em>
                        <u>App</u>
                      </em>
                      : ações específicas de acesso no aplicativo do condomínio.
                    </li>
                    <li>
                      <em>
                        <u>Correios</u>
                      </em>
                      : ações relacionadas à Caixa de Correio Inteligente dos
                      Correios.
                    </li>
                    <li>
                      <em>
                        <u>Lockin</u>
                      </em>
                      : ações do armário e entrega.
                    </li>
                  </ul>
                }
                placement="right"
                style={{ height: 32 }}>
                <IconButton size="small">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ marginTop: '20px' }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          fontSize: '1.2rem',
                          color: colors.tertiary_40,
                        }}>
                        Data
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          fontSize: '1.2rem',
                          color: colors.tertiary_40,
                        }}>
                        Evento
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          fontSize: '1.2rem',
                          color: colors.tertiary_40,
                        }}>
                        Porta
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          fontSize: '1.2rem',
                          color: colors.tertiary_40,
                        }}>
                        Categoria
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.map(log => (
                      <TableRow key={log.id}>
                        <TableCell>
                          {new Date(log.event_date).toLocaleString('pt-br')}
                        </TableCell>
                        <TableCell>{log.event_name}</TableCell>
                        <TableCell>
                          {log.lock_name
                            ? log.lock_name.substr(log.lock_name.length - 2)
                            : '-'}
                        </TableCell>
                        <TableCell>{capitalize(log.category)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={totalLogs}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={event =>
                    handleChangeRowsPerPage(event.target.value)
                  }
                  labelRowsPerPage={'Recibos por página'}
                />
              </TableContainer>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
