import PropTypes from 'prop-types';

import { colors } from '../../../styles/colors';

Title.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  bold: PropTypes.bool,
  light: PropTypes.bool,
};

export default function Title({ text, size, color, bold, light, ...props }) {
  return (
    <span
      style={{
        fontFamily: 'Montserrat',
        fontWeight: bold ? 600 : light ? 400 : 500,
        fontSize: size ? size : '1.2rem',
        color: color ? color : colors.basic_70,
        ...props,
      }}
    >
      {text}
    </span>
  );
}
