/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyIcon from '@mui/icons-material/Key';
import PropTypes from 'prop-types';

import Loading from '../../components/Loading';
import Toast from '../../components/Toast';
import ModalHabitation from './components/ModalHabitation';
import ModalPasscodes from './components/ModalPasscodes';

import {
  createHabitation,
  deleteHabitation,
  getBlockHabitations,
  getBlocks,
  updateHabitation,
} from '../../api/index';

import { sortData } from '../../utils/sortData';

import { colors } from '../../styles/colors';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Habitations.propTypes = {
  handler: PropTypes.func,
};

export default function Habitations(props) {
  const toastRef = useRef();
  const [allBlocks, setAllBlocks] = useState(null);
  const [block, setBlock] = useState('');
  const [habitations, setHabitations] = useState('');
  const [habitationsTotal, setHabitationsTotal] = useState(0);
  const [habitationsTable, setHabitationTable] = useState('');
  const [habitationSelected, setHabitationSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPasscodesOpen, setModalPasscodesOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleClickOpen = habitation => {
    setHabitationSelected(habitation);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setHabitationSelected('');
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getHabitations(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = rowsPerPageInput => {
    const newRowsPerPage = parseInt(rowsPerPageInput, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getHabitations(0, newRowsPerPage);
  };

  const openModalCreate = () => {
    setHabitationSelected('');
    setModalType('create');
    setModalOpen(true);
  };

  const openModal = (habitation, index) => {
    const habitationData = {
      id: habitation.id,
      name: habitation.name,
      cellphone: habitation.cellphone,
      email: habitation.email,
      habitation_external_id: habitation.external_id,
      habitation_external_uuid: habitation.external_uuid,
      index: index,
    };
    setHabitationSelected(habitationData);
    if (habitation.name) {
      setIsButtonDisabled(false);
    }
    setModalType('edit');
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setIsButtonDisabled(true);
    setHabitationSelected('');
    setOpen(false);
  };

  const openPasscodesModal = async habitation => {
    setHabitationSelected(habitation);
    setModalPasscodesOpen(true);
  };

  const closePasscodesModal = () => {
    setHabitationSelected('');
    setModalPasscodesOpen(false);
  };

  const createHabitationMethod = async () => {
    const response = await createHabitation(block.id, habitationSelected);
    if (response.status === 200) {
      getHabitations(page, rowsPerPage);
      toastRef.current.handleOpen('Apartamento criado com sucesso!', 'success');
      closeModal();
    } else {
      toastRef.current.handleOpen('Erro ao criar apartamento.', 'error');
    }
  };

  const updateHabitationMethod = async () => {
    const response = await updateHabitation(habitationSelected);
    if (response.status === 200) {
      getHabitations(page, rowsPerPage);
      toastRef.current.handleOpen(
        'Apartamento atualizado com sucesso!',
        'success',
      );
      closeModal();
    } else {
      toastRef.current.handleOpen('Erro ao editar apartamento.', 'error');
    }
  };

  const deleteHabitationMethod = async habitation_id => {
    const response = await deleteHabitation(habitation_id);
    if (response.status === 200) {
      handleClose();
      getHabitations(page, rowsPerPage);
      toastRef.current.handleOpen(
        'Apartamento deletado com sucesso!',
        'success',
      );
      closeModal();
    } else {
      toastRef.current.handleOpen('Erro ao deletar apartamento.', 'error');
    }
  };

  const getHabitations = async (offset = 0, limit = 10) => {
    let newHabitations = [];
    const habitations = await getBlockHabitations(block.id, limit, offset + 1);
    setHabitations(habitations.data?.result?.habitations);
    setHabitationsTotal(habitations.data?.result?.total);
    for (let index = 0; index < 5; index++) {
      newHabitations[index] = habitations.data[index];
    }
    setHabitationTable(newHabitations);
  };

  const handleChangeHabitationName = nameInput => {
    const updatedHabitation = { ...habitationSelected, name: nameInput };
    if (nameInput.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    setHabitationSelected(updatedHabitation);
  };

  const handleChangeHabitationEmail = emailInput => {
    const updatedHabitation = { ...habitationSelected, email: emailInput };
    setHabitationSelected(updatedHabitation);
  };

  const handleChangeHabitationExternalId = externalIdInput => {
    const updatedHabitation = {
      ...habitationSelected,
      habitation_external_id: externalIdInput,
    };
    setHabitationSelected(updatedHabitation);
  };

  const handleChangeHabitationExternalUuId = externalUuidInput => {
    const updatedHabitation = {
      ...habitationSelected,
      habitation_external_uuid: externalUuidInput,
    };
    setHabitationSelected(updatedHabitation);
  };

  const handleChangeHabitationCellphone = clientCellphone => {
    const updatedHabitation = {
      ...habitationSelected,
      cellphone: clientCellphone
        .replace('(', '')
        .replace(')', '')
        .replace(/\s+/g, ''),
    };
    setHabitationSelected(updatedHabitation);
  };

  const handleChangeBlock = blockInput => {
    const newBlock = JSON.parse(blockInput);
    setBlock(newBlock);
    setPage(0);
    setRowsPerPage(10);
  };

  useEffect(() => {
    props.handler('Moradias');
    const fetchData = async () => {
      const response = await getBlocks();
      if (response.status === 200) {
        const sortedBlocks = sortData(response.data, 'name');
        setAllBlocks(sortedBlocks);
      } else {
        toastRef.current.handleOpen('Erro ao recuperar blocos.', 'error');
      }
    };
    fetchData();
  }, [props]);

  useEffect(() => {
    if (block?.id) {
      getHabitations();
    }
  }, [block?.id]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Toast ref={toastRef} />

      {loading && <Loading />}

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle id="alert-dialog-slide-title">
          {'Tem certeza que deseja excluir esse apartamento?'}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Apartamento: {habitationSelected.name}
            <br></br>
            Telefone: {habitationSelected.cellphone}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ fontWeight: 600, color: colors.basic_40 }}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deleteHabitationMethod(habitationSelected.id);
            }}
            style={{ fontWeight: 600, color: colors.tertiary_40 }}>
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      <ModalPasscodes
        open={modalPasscodesOpen}
        close={closePasscodesModal}
        habitation_id={habitationSelected.id}
        setLoading={setLoading}
      />

      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description">
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '500px',
            minHeight: '500px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '10px',
          }}>
          <TextField
            label="Apartamento"
            required
            variant="outlined"
            value={habitationSelected?.name}
            onChange={event => handleChangeHabitationName(event.target.value)}
            style={{ width: '50%' }}
          />
          <TextField
            label="Email"
            variant="outlined"
            value={habitationSelected?.email}
            onChange={event => handleChangeHabitationEmail(event.target.value)}
            style={{ width: '50%' }}
          />
          <MuiPhoneNumber
            variant="outlined"
            label="Celular do cliente"
            defaultCountry={'br'}
            value={habitationSelected?.cellphone}
            onChange={handleChangeHabitationCellphone}
            style={{ width: '50%' }}
          />
          <Divider />
          <Typography>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            Informações de integração
          </Typography>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TextField
              label="ID Externo"
              variant="outlined"
              value={habitationSelected?.habitation_external_id}
              onChange={event =>
                handleChangeHabitationExternalId(event.target.value)
              }
              style={{ marginRight: '10px' }}
            />
            <TextField
              label="UUID Externo"
              variant="outlined"
              value={habitationSelected?.habitation_external_uuid}
              onChange={event =>
                handleChangeHabitationExternalUuId(event.target.value)
              }
            />
          </Collapse>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: '10px',
            }}>
            <Button
              onClick={() => {
                closeModal();
              }}
              variant="contained"
              style={{
                backgroundColor: 'white',
                color: colors.tertiary_40,
                marginRight: '10px',
                border: `1px solid ${colors.tertiary_40}`,
                borderRadius: '10px',
              }}>
              Fechar
            </Button>
            {modalType === 'edit' ? (
              <Button
                disabled={isButtonDisabled}
                onClick={() => {
                  updateHabitationMethod();
                }}
                variant="contained"
                style={{
                  backgroundColor: isButtonDisabled
                    ? colors.basic_40
                    : colors.tertiary_40,
                  color: 'white',
                  borderRadius: '10px',
                }}>
                Confirmar edição
              </Button>
            ) : (
              <Button
                disabled={isButtonDisabled}
                onClick={() => {
                  createHabitationMethod();
                }}
                variant="contained"
                style={{
                  backgroundColor: isButtonDisabled
                    ? colors.basic_40
                    : colors.tertiary_40,
                  color: 'white',
                  borderRadius: '10px',
                }}>
                Criar Apartamento
              </Button>
            )}
          </div>
        </div>
      </Modal>

      <div>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: colors.basic_70,
          }}>
          Selecione o bloco ou rua
        </Typography>
      </div>

      <FormControl style={{ minWidth: 120 }}>
        {allBlocks ? (
          <>
            <InputLabel htmlFor="demo-simple-select-label">
              Blocos/Ruas
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              defaultValue={''}
              onChange={event => handleChangeBlock(event.target.value)}>
              {allBlocks.map(block => {
                return (
                  <MenuItem key={block.id} value={JSON.stringify(block)}>
                    {block.name}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        ) : (
          <Loading />
        )}
      </FormControl>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '15px',
        }}>
        {habitationsTable !== '' ? (
          <>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}
                      align="center">
                      ID
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Apartamento
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Telefone
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}
                      align="center">
                      Email
                    </TableCell>
                    <TableCell
                      style={{
                        color: colors.tertiary_40,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <KeyIcon />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {habitations.map((habitation, index) => (
                    <ModalHabitation
                      key={habitation.id}
                      habitation={habitation}
                      index={index}
                      openModal={openModal}
                      openPasscodesModal={openPasscodesModal}
                      handleClickOpen={handleClickOpen}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={habitationsTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={event =>
                  handleChangeRowsPerPage(event.target.value)
                }
                labelRowsPerPage={'Apartamentos por página'}
              />
            </TableContainer>

            <div style={{ alignSelf: 'flex-end' }}>
              <Button
                onClick={() => {
                  openModalCreate();
                }}
                variant="contained"
                style={{
                  marginTop: '10px',
                  backgroundColor: colors.tertiary_40,
                  color: 'white',
                  borderRadius: '10px',
                }}>
                Criar novo apartamento
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
