import { createRoot } from 'react-dom/client';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

import App from './App';
import './index.css';

const theme = createMuiTheme({}, ptBR);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
);
