import { useState, useRef } from 'react';
import { TextField } from '@material-ui/core';
import { Button } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import PropTypes from 'prop-types';

import { login } from '../../api/index';

import Loading from '../../components/Loading';
import Toast from '../../components/Toast';

import logo from '../../images/logo-inteira.png';
import { colors } from '../../styles/colors';

Login.propTypes = {
  setToken: PropTypes.func,
  setType: PropTypes.func,
  setCondoCompany: PropTypes.func,
};

export default function Login({ setToken, setType, setCondoCompany }) {
  const toastRef = useRef();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    const response = await login(email, password);
    if (response === 404) {
      setLoading(false);
      toastRef.current.handleOpen('E-mail e/ou senha incorretos.', 'error');
      return false;
    }
    sessionStorage.setItem('token', JSON.stringify(response.token));
    sessionStorage.setItem('type', JSON.stringify(response.type));
    sessionStorage.setItem(
      'condoCompany',
      JSON.stringify(response.condoCompanyData),
    );
    setToken(response.token);
    setCondoCompany(response.condoCompanyData);
    setType(response.type);
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Toast ref={toastRef} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          justifyContent: 'space-between',
          height: '35%',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ alignSelf: 'center' }}
            src={logo}
            width={'40%'}
            alt=""
          />
        </div>

        <div>
          <TextField
            fullWidth={true}
            label="Email"
            onChange={e => setEmail(e.target.value)}
            autoComplete="username"
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
          }}
        >
          <div>
            <TextField
              fullWidth={true}
              label="Senha"
              type={isVisible ? 'text' : 'password'}
              onChange={e => setPassword(e.target.value)}
              autoComplete="current-password"
              style={{ width: '200px', marginLeft: '64px' }}
            />
          </div>

          <Button
            type="button"
            size="small"
            onClick={() => setIsVisible(!isVisible)}
            style={{
              background: colors.white,
              border: 'none',
              marginTop: '15px',
              padding: '0px',
            }}
          >
            {isVisible ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <VisibilityOutlinedIcon />
            )}
          </Button>
        </div>

        <Button
          type="submit"
          variant="contained"
          style={{
            backgroundColor: colors.blue,
            color: colors.background,
            marginTop: 30,
          }}
        >
          {!loading ? 'Confirmar' : <Loading />}
        </Button>
      </div>
    </form>
  );
}
