import PropTypes from 'prop-types';

import styles from './styles.module.scss';

DashboardCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.string,
};

export default function DashboardCard({ title, subtitle, data }) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>
          {title}
        </h2>
        <span className={styles.subtitle}>
          {subtitle}
        </span>
      </div>
      <h1 className={styles.data}>
        {data ?? 'Erro'}
      </h1>
    </div>
  );
}
