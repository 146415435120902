export const formatCellphone = cellphone => {
  if (cellphone) {
    if (cellphone.length === 14) {
      const countryCode = cellphone.substring(0, 3);
      const ddd = cellphone.substring(3, 5);
      const firstPart = cellphone.substring(5, 10);
      const secondPart = cellphone.substring(10, 14);

      return `${countryCode} (${ddd}) ${firstPart}-${secondPart}`;
    } else {
      return cellphone;
    }
  } else {
    return '';
  }
};
