import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PropTypes from 'prop-types';

import { logout } from './api/index';

import Login from './pages/Login';
import { Menu, MenuRoutes } from './components/Menu';
import { Header } from './components/Typography/Header';
import AccordionItem from './components/AccordionItem';

import { patchNotes } from './utils/patchNotes';

import { useStyles } from './style';
import { colors } from './styles/colors';

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles(props);
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [token, setToken] = useState('');
  const [condoCompany, setCondoCompany] = useState('');
  const [type, setType] = useState('');
  const [title, setTitle] = useState('Tela Inicial');

  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const getCondoCompany = () => {
    const condoOrCompany = sessionStorage.getItem('condoCompany');
    const condoCompany = JSON.parse(condoOrCompany);
    return condoCompany;
  };

  const getType = () => {
    const typeStorage = sessionStorage.getItem('type');
    const type = JSON.parse(typeStorage);
    return type;
  };

  useEffect(() => {
    if (localStorage.getItem('token') && !sessionStorage.getItem('token')) {
      sessionStorage.setItem('token', localStorage.getItem('token'));
      sessionStorage.setItem('type', localStorage.getItem('type'));
      sessionStorage.setItem(
        'condoCompany',
        localStorage.getItem('condoCompany'),
      );
      sessionStorage.setItem('adminType', localStorage.getItem('adminType'));

      localStorage.removeItem('token');
      localStorage.removeItem('type');
      localStorage.removeItem('condoCompany');
      localStorage.removeItem('adminType');
    }
    setToken(getToken());
    setType(getType());
    setCondoCompany(getCondoCompany());
  }, [props]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLinkChange = title => {
    setMobileOpen(false);
    setTitle(title);
  };

  const handleLogout = () => {
    setToken('');
    setCondoCompany('');
    setType('');
    logout();
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (!token) {
    return (
      <Login
        setToken={setToken}
        setCondoCompany={setCondoCompany}
        setType={setType}
      />
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}>
              <Menu
                title={title}
                type={type}
                condoCompany={condoCompany}
                logout={handleLogout}
                mobile
              />
            </Drawer>
          </Hidden>

          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open>
              <Menu
                title={title}
                type={type}
                condoCompany={condoCompany}
                logout={handleLogout}
              />
            </Drawer>
          </Hidden>
        </nav>

        <div className={classes.content}>
          <div className={classes.appBarBack} />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Header
                text={condoCompany?.name || ''}
                color="inherit"
                padding="1px"
              />
              <IconButton color="inherit" size="medium" onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <div className={classes.toolbar} style={{ marginTop: '50px' }} />

          <Route
            exact={true}
            path={'/'}
            render={() => (
              <div className={classes.textContainer}>
                <Typography
                  variant="h4"
                  gutterBottom
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                  }}>
                  Bem-vindo(a),{' '}
                  {condoCompany && condoCompany.name ? condoCompany.name : ''},
                  à área de gerenciamento!
                </Typography>

                {type === 'condo' ? (
                  <div className={classes.patchNotesContainer}>
                    {patchNotes.map(note => (
                      <AccordionItem
                        defaultExpanded={note.id === patchNotes[0].id}
                        key={note.id}
                        title={note.title}
                        text={note.text}
                      />
                    ))}
                  </div>
                ) : (
                  <Typography
                    style={{
                      marginTop: 10,
                      marginLeft: 10,
                      fontWeight: 'bold',
                      color: colors.dark_purple,
                    }}
                    variant="h4">
                    Novidades em breve!
                  </Typography>
                )}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '20px',
                  }}>
                  <WhatsAppIcon color="primary" />
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      marginLeft: '4px',
                    }}
                    gutterBottom>
                    <a
                      style={{
                        textDecoration: 'none',
                        color: '#1976d2',
                      }}
                      href={process.env.REACT_APP_FEEDBACK_URL}>
                      (61) 9 9681-8478
                    </a>
                  </Typography>
                </div>
              </div>
            )}
          />

          <MenuRoutes type={type} handler={handleLinkChange} />
        </div>
      </Router>
    </div>
  );
}
