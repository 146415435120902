import {
  Button,
  FormControl,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PropTypes from 'prop-types';

import { colors } from '../../../styles/colors';

ModalPasscodes.propTypes = {
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  passcodes: PropTypes.object,
  handlePasscodeStatusChange: PropTypes.func,
  lock: PropTypes.object,
  handleClickOpen: PropTypes.func,
  rowsPerPageModal: PropTypes.number,
  pageModal: PropTypes.number,
  handleChangePageModal: PropTypes.func,
  handleChangeRowsPerPageModal: PropTypes.func,
};

export default function ModalPasscodes({
  modalOpen,
  closeModal,
  passcodes,
  lock,
  pageModal,
  rowsPerPageModal,
  handleClickOpen,
  handlePasscodeStatusChange,
  handleChangePageModal,
  handleChangeRowsPerPageModal,
}) {
  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <div
        style={{
          backgroundColor: 'white',
          minWidth: '200px',
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-around',
          padding: '10px',
        }}>
        {passcodes ? (
          <div>
            <TableContainer component={Paper}>
              <Typography
                variant="h4"
                gutterBottom
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  color: colors.tertiary_40,
                  textAlign: 'center',
                  padding: '10px',
                }}>
                Senhas de moradores
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Senha
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Data de criação
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Data de expiração
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Moradia
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Bloco
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Ativa
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {passcodes.passcodes_guests?.map(passcode => (
                    <TableRow key={passcode.id}>
                      <TableCell>{passcode.code}</TableCell>
                      <TableCell>
                        {new Date(passcode.created_at).toLocaleString('pt-br')}
                      </TableCell>
                      <TableCell>
                        {new Date(passcode.endDateTime).toLocaleString('pt-br')}
                      </TableCell>
                      <TableCell>{passcode.habitation_name}</TableCell>
                      <TableCell>{passcode.block_name}</TableCell>
                      <TableCell>
                        <FormControl
                          sx={{
                            m: 1,
                            minWidth: 80,
                          }}>
                          <Select
                            value={passcode.is_active}
                            onChange={event =>
                              handlePasscodeStatusChange(
                                lock.id,
                                passcode.id,
                                event.target.value,
                              )
                            }>
                            <MenuItem value={true}>Ativa</MenuItem>
                            <MenuItem value={false}>Desativada</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {passcode.expired ? (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <Tooltip
                              title="Portas em vermelho possuem senhas expiradas e necessitam de realização da logística reversa."
                              placement="top">
                              <ErrorOutlineIcon
                                style={{
                                  color: '#ca2f12',
                                }}
                              />
                            </Tooltip>
                            <Button
                              onClick={() => {
                                handleClickOpen(passcode);
                              }}
                              variant="contained"
                              style={{
                                backgroundColor: colors.tertiary_40,
                                color: 'white',
                              }}>
                              Confirmar logística reversa
                            </Button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={passcodes.passcodes_guests_total}
                rowsPerPage={rowsPerPageModal}
                page={pageModal}
                onChangePage={handleChangePageModal}
                onChangeRowsPerPage={event =>
                  handleChangeRowsPerPageModal(event.target.value)
                }
                labelRowsPerPage={'Senhas por página'}
              />

              <Typography
                variant="h4"
                gutterBottom
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  color: colors.tertiary_40,
                  textAlign: 'center',
                }}>
                Senhas de Administradores
              </Typography>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Senha
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Ativa
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {passcodes.passcodes_admin?.map(passcode => (
                    <TableRow key={passcode.id}>
                      <TableCell>{passcode.code}</TableCell>
                      <TableCell>
                        {passcode.is_active ? (
                          <Typography>Sim</Typography>
                        ) : (
                          <Typography>Não</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginTop: '10px',
              }}>
              <Button
                onClick={() => {
                  closeModal();
                }}
                variant="contained"
                style={{
                  backgroundColor: 'white',
                  color: colors.tertiary_40,
                  marginRight: '10px',
                  border: `1px solid ${colors.tertiary_40}`,
                  borderRadius: '10px',
                }}>
                Fechar
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
}
