import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { StepOne } from './components/StepOne';
import { StepTwo } from './components/StepTwo';
import { StepThree } from './components/StepThree';
import { StepFour } from './components/StepFour';
import Toast from '../../components/Toast';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

RemoteDelivery.propTypes = {
  handler: PropTypes.func,
};

export default function RemoteDelivery({ ...props }) {
  const classes = useStyles();
  const toastRef = useRef();
  const [step, setStep] = useState(1);
  const [lock, setLock] = useState({
    controller: { id: null, name: null },
    block: { id: null, name: null },
    habitation: { id: null, name: null, ex_id: null, ex_uuid: null },
    size: null,
  });

  const stepForward = () => {
    setStep(step + 1);
  };

  const stepBackward = () => {
    setStep(step - 1);
  };

  const resetStep = () => {
    toastRef.current.handleOpen(
      'Entrega remota realizada com sucesso!',
      'success',
    );
    setStep(1);
  };

  useEffect(() => {
    props.handler('Criar Entrega');
  }, [props]);

  return (
    <div className={classes.container}>
      <Toast ref={toastRef} />
      {step === 1 ? (
        <StepOne lock={lock} setLock={setLock} stepForward={stepForward} />
      ) : step === 2 ? (
        <StepTwo
          lock={lock}
          setLock={setLock}
          stepForward={stepForward}
          stepBackward={stepBackward}
        />
      ) : step === 3 ? (
        <StepThree
          lock={lock}
          setLock={setLock}
          stepForward={stepForward}
          stepBackward={stepBackward}
        />
      ) : step === 4 ? (
        <StepFour
          resetStep={resetStep}
          lock={lock}
          setLock={setLock}
          stepBackward={stepBackward}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
