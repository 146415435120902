import axios from 'axios';

const baseUrl = process.env.REACT_APP_LOOPBOX_API_URL;

export const getControllersPerLocation = async (latitude, longitude, state) => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/controllers/delivery/close', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
      params: {
        latitude: latitude,
        longitude: longitude,
        state: state,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response;
    });
};

export const createDelivery = async (
  controller_id,
  size,
  client_email,
  client_cellphone,
) => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v1/delivery/create/',
      {
        controller_id: controller_id,
        lock_size: size,
        client_cellphone: client_cellphone,
        client_email: client_email,
      },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCompanyDeliveries = async (limit, offset) => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/company/delivery', {
      params: {
        limit: limit,
        offset: offset,
      },
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getDeliveryDetails = async delivery_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/delivery/' + delivery_id, {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createDeliveryAdmin = async (
  deliveryIdentifier,
  clientCellphone,
  controllerId,
  lockSize,
  passcodeType,
) => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v2/delivery/create',
      {
        delivery_identifier: deliveryIdentifier,
        client_cellphone: clientCellphone,
        controller_id: controllerId,
        lock_size: lockSize,
        passcode_type: passcodeType,
      },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getDeliveriesAdmin = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/deliveries', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getControllersAdmin = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/controllers', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getLocksSizes = async controllerId => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/locker/' + controllerId + '/locks/sizes', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const sendSmsDelivery = async deliveryId => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v2/delivery/' + deliveryId + '/sms',
      {
        delivery_id: deliveryId,
      },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
