import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import Title from '../../../components/Typography/Title';
import { colors } from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '15px',
  },

  box: {
    borderColor: colors.dark_purple,
    borderStyle: 'dashed',
    borderWidth: '2px',
    padding: '18.5px 50px 18.5px 50px',
    alignItems: 'center',
    borderRadius: '8px',
    minWidth: '500px',
  },
}));

LockCard.propTypes = {
  lock: PropTypes.object,
};

export function LockCard({ lock }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Title text={'Armário Selecionado:'} />
        <Title bold text={lock.controller.name || ''} textAlign="center" />
      </div>
      <div className={classes.box}>
        <div>
          <Title light color={colors.dark_purple} text={'Bloco: '} />
          <Title color={colors.dark_purple} text={lock.block.name} />
        </div>
        <div>
          <Title light color={colors.dark_purple} text={'Residência: '} />
          <Title color={colors.dark_purple} text={lock.habitation.name} />
        </div>
        <div>
          <Title light color={colors.dark_purple} text={'Tamanho da Porta: '} />
          <Title color={colors.dark_purple} text={lock.size} />
        </div>
      </div>
    </div>
  );
}
