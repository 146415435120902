/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getControllerBlocks } from '../../../api/index';

import Title from '../../../components/Typography/Title';
import { DefaultButton } from '../../../components/Buttons/DefaultButton';
import DefaultInput from '../../../components/Inputs/DefaultInput';
import { LockCard } from './LockCard';
import { GoBackButton } from './GoBackButton';
import Loading from '../../../components/Loading';
import Toast from '../../../components/Toast';

import { sortData } from '../../../utils/sortData';

import { colors } from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '15px',
  },
  card: {
    margin: '45px 0px',
  },
  grid: {
    minHeight: '100px',
    maxHeight: '400px',
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

StepTwo.propTypes = {
  stepForward: PropTypes.func,
  stepBackward: PropTypes.func,
  lock: PropTypes.object,
  setLock: PropTypes.func,
};

export function StepTwo({ stepForward, stepBackward, lock, setLock }) {
  const classes = useStyles();
  const toastRef = useRef();
  const [filtered, setFiltered] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleBlocks = async () => {
    const res = await getControllerBlocks(lock.controller.id);
    if (res.status === 200) {
      const sortedBlocks = sortData(res.data, 'name');
      setBlocks(sortedBlocks);
      setFiltered(sortedBlocks);
    } else {
      toastRef.current.handleOpen('Erro ao recuperar  blocos.', 'error');
    }
    setLoading(false);
  };

  const handleBlockSelection = (id, name) => {
    setLock({
      ...lock,
      block: {
        id,
        name: name.toLowerCase().replace('bloco', '').toUpperCase(),
      },
      habitation: {
        id: null,
        name: null,
        ex_id: null,
        ex_uuid: null,
      },
      size: null,
    });
    stepForward();
  };

  const sanitized = str => {
    return str
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  };

  const filterBlocksByName = e => {
    const name = e.target.value;
    const res = blocks.filter(c => sanitized(c.name).includes(sanitized(name)));
    setFiltered(res);
  };

  useEffect(() => {
    handleBlocks();
  }, []);

  return (
    <>
      <Toast ref={toastRef} />

      <div className={classes.header} onClick={stepBackward}>
        <GoBackButton />
      </div>

      <div className={classes.title}>
        <Title text="Selecione o bloco/rua" bold />
      </div>

      <DefaultInput
        onChange={filterBlocksByName}
        sx={{ marginBottom: '20px' }}
      />

      {loading ? (
        <Loading />
      ) : blocks.length ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={1}
          className={classes.grid}>
          {filtered.map(block => (
            <Grid item key={block.id}>
              <DefaultButton
                onClick={() => handleBlockSelection(block.id, block.name)}
                selected={lock.block.id === block.id}
                sx={{ fontSize: '1rem' }}>
                {block.name}
              </DefaultButton>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Title
          text="Nenhum bloco encontrado."
          bold
          paddingTop="15px"
          fontSize="1.4rem"
          color={colors.negative_40}
        />
      )}

      <div className={classes.card}>
        <LockCard lock={lock} />
      </div>

      <DefaultButton disabled sx={{ fontSize: '1.3rem' }} color="secondary">
        Confirmar dados
      </DefaultButton>
    </>
  );
}
