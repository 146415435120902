import { useEffect, useState } from 'react';
import {
  Paper,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { getDeliveriesAdmin } from '../../api/delivery';

import ModalDeliveryAdmin from './components/ModalDeliveryAdmin';
import SmsAlert from './components/SmsAlert';

import { colors } from '../../styles/colors';

DeliveryAdmin.propTypes = {
  handler: PropTypes.func,
};

export default function DeliveryAdmin(props) {
  const [deliveriesTable, setDeliveriesTable] = useState('');
  const [deliveries, setDeliveries] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getDeliveries = async () => {
    const deliveries = await getDeliveriesAdmin();
    let newDeliveriesTable = [];
    setPage(0);
    setDeliveries(deliveries.data.result);
    if (deliveries.length > 0) {
      for (let index = 0; index < 5; index++) {
        newDeliveriesTable[index] = deliveries.data.result[index];
      }
    }
    setDeliveriesTable(newDeliveriesTable);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    getDeliveries();
  };

  const handleDeliveryStatus = deliveryStatus => {
    if (deliveryStatus === 'in_progress') {
      return (
        <div
          style={{
            backgroundColor: colors.gray_dark,
            color: colors.white,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}
        >
          Em progresso
        </div>
      );
    }

    if (deliveryStatus === 'delivered') {
      return (
        <div
          style={{
            backgroundColor: '#F2C94C',
            color: colors.white,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}
        >
          Entregue
        </div>
      );
    }

    if (deliveryStatus === 'completed') {
      return (
        <div
          style={{
            color: colors.black,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}
        >
          Concluída
        </div>
      );
    }

    if (deliveryStatus === 'expired') {
      return (
        <div
          style={{
            backgroundColor: colors.red_dark,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}
        >
          Expirada
        </div>
      );
    }

    return (
      <div
        style={{
          backgroundColor: colors.red_dark,
          color: colors.white,
          height: '30px',
          width: '100px',
          borderRadius: '4px',
          textAlign: 'center',
          paddingTop: '5px',
        }}
      >
        Cancelada
      </div>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    props.handler('Entregas');
    getDeliveries();
  }, [props]);

  const TableHeader = withStyles(() => ({
    root: {
      fontWeight: 'bold',
      backgroundColor: '#d2cfd1',
      height: '50px',
    },
  }))(TableCell);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <ModalDeliveryAdmin open={modalOpen} close={closeModal} />

      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}
      >
        {deliveriesTable !== '' && deliveries !== undefined ? (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Identificador da entrega
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Identificador do pedido
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Telefone do cliente
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Email do cliente
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Porta
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Senha de entrega
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Senha de retirada
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Data de criação
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Status da entrega
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}></TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {deliveries
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(delivery => (
                    <TableRow key={delivery.id}>
                      <TableCell style={{ textAlign: 'center' }}>
                        {delivery.id}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {delivery.delivery_identifier || '-'}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {delivery.client_cellphone}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {delivery.client_email || '-'}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {delivery.lock_name}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {delivery.code_delivery || '-'}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {delivery.code_receive || '-'}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {new Date(delivery.created_datetime).toLocaleString(
                          'pt-br',
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        {handleDeliveryStatus(delivery.status)}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>
                        <SmsAlert
                          disabled={delivery.code_delivery ? true : false}
                          deliveryId={delivery.id}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={deliveries.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={'Recibos por página'}
            />
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Identificador da entrega
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Identificador do pedido
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Telefone do cliente
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Email do cliente
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Porta
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Senha de entrega
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Senha de retirada
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Data de criação
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}>
                    Status da entrega
                  </TableHeader>
                  <TableHeader style={{ textAlign: 'center' }}></TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      marginTop: 10,
                      marginLeft: 15,
                      fontWeight: 'bold',
                    }}
                  >
                    Você ainda não possui pedidos de entrega.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <div style={{ alignSelf: 'flex-end' }}>
          <Button
            onClick={() => openModal()}
            variant="contained"
            style={{
              backgroundColor: colors.green_dark,
              color: colors.white,
              marginTop: '15px',
              height: '40px',
            }}
          >
            Criar pedido de entrega
          </Button>
        </div>
      </div>
    </div>
  );
}
