import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  withStyles,
} from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

DeliveryDetails.propTypes = {
  details: PropTypes.string,
};

export default function DeliveryDetails(props) {
  const [value, setValue] = useState(0);

  const TableHeaderCell = withStyles(() => ({
    root: {
      fontWeight: 'bold',
    },
  }))(TableCell);

  const handlePasscodeCategory = passcodeCategory => {
    if (passcodeCategory === 'delivery') return 'Entrega';
    return 'Retirada';
  };

  const handleDeliveryStatus = deliveryStatus => {
    if (deliveryStatus === 'in_progress') return 'Em progresso';
    if (deliveryStatus === 'delivered') return 'Enviada';
    if (deliveryStatus === 'completed') return 'Concluída';
    if (deliveryStatus === 'expired') return 'Expirada';
    return 'Cancelada';
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
        width: '750px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {props.details !== '' ? (
        <>
          {/* CABEÇALHO DAS ABAS */}
          <AppBar position="static">
            <Tabs
              value={value}
              indicatorColor="secondary"
              onChange={(event, value) => setValue(value)}
              centered
            >
              <Tab label="Dados da Entrega" {...a11yProps(0)} />
              <Tab label="Dados do Armário" {...a11yProps(1)} />
              <Tab label="Senha" {...a11yProps(2)} />
              <Tab label="Histórico de uso" {...a11yProps(3)} />
            </Tabs>
          </AppBar>

          {/* ENTREGA */}
          <TabPanel value={value} index={0}>
            <div style={{ width: '700px' }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow hover={true}>
                      <TableHeaderCell>Celular do cliente</TableHeaderCell>
                      <TableHeaderCell>E-mail do cliente</TableHeaderCell>
                      <TableHeaderCell>Status da entrega</TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover={true}>
                      <TableCell>
                        {props.details.delivery_data.client_cellphone}
                      </TableCell>
                      <TableCell>
                        {props.details.delivery_data.client_email}
                      </TableCell>
                      <TableCell>
                        {handleDeliveryStatus(
                          props.details.delivery_data.status,
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow hover={true}>
                      <TableHeaderCell>Data de criação</TableHeaderCell>
                      <TableHeaderCell>Data da entrega</TableHeaderCell>
                      <TableHeaderCell>Data da retirada</TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover={true}>
                      <TableCell>
                        {props.details.delivery_data.created_datetime !== null
                          ? new Date(
                              props.details.delivery_data.created_datetime,
                            ).toLocaleString('pt-br')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {props.details.delivery_data.delivered_datetime !== null
                          ? new Date(
                              props.details.delivery_data.delivered_datetime,
                            ).toLocaleString('pt-br')
                          : '-'}
                      </TableCell>
                      <TableCell>
                        {props.details.delivery_data.completed_datetime !== null
                          ? new Date(
                              props.details.delivery_data.completed_datetime,
                            ).toLocaleString('pt-br')
                          : '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>

          {/* ARMÁRIO */}
          <TabPanel value={value} index={1}>
            <div style={{ width: '700px' }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow hover={true}>
                      <TableHeaderCell>Localização do Armário</TableHeaderCell>
                      <TableHeaderCell>Porta</TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover={true}>
                      <TableCell>
                        {props.details.address.address_line}
                      </TableCell>
                      <TableCell>{props.details.lock.lock_number}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>

          {/* SENHA */}
          <TabPanel value={value} index={2}>
            <div style={{ width: '700px' }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow hover={true}>
                      <TableHeaderCell>Senha</TableHeaderCell>
                      <TableHeaderCell>Tipo de senha</TableHeaderCell>
                      <TableHeaderCell>Ativa</TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.details.delivery_passcodes.map(passcode => (
                      <TableRow key={passcode.id} hover={true}>
                        <TableCell>{passcode.code}</TableCell>
                        <TableCell>
                          {handlePasscodeCategory(passcode.category)}
                        </TableCell>
                        <TableCell>
                          {passcode.is_active ? 'Sim' : 'Não'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>

          {/* HISTÓRICO DE USO */}
          <TabPanel value={value} index={3}>
            <div style={{ width: '725px' }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow hover={true}>
                      <TableHeaderCell>Data</TableHeaderCell>
                      <TableHeaderCell>Nome</TableHeaderCell>
                      <TableHeaderCell>Evento</TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.details.delivery_logs.map(log => (
                      <TableRow key={log.id} hover={true}>
                        <TableCell>
                          {new Date(log.event_date).toLocaleString('pt-br')}
                        </TableCell>
                        <TableCell>{log.lock_name}</TableCell>
                        <TableCell>{log.event_name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
