import { useEffect, useRef, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getLocksPerSize, remoteDelivery } from '../../../api/index';

import Title from '../../../components/Typography/Title';
import { DefaultButton } from '../../../components/Buttons/DefaultButton';
import Toast from '../../../components/Toast';
import { LockCard } from './LockCard';
import { GoBackButton } from './GoBackButton';
import Loading from '../../../components/Loading';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '15px',
  },
  card: {
    margin: '45px 0px',
  },
  grid: {
    paddingTop: '15px',
    minHeight: '100px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  sizeButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  sizeText: {
    textAlign: 'flex-start',
    padding: '5px 0',
  },
}));

StepFour.propTypes = {
  resetStep: PropTypes.func,
  stepBackward: PropTypes.func,
  lock: PropTypes.object,
  setLock: PropTypes.func,
};

export function StepFour({ resetStep, stepBackward, lock, setLock }) {
  const classes = useStyles();
  const toastRef = useRef();
  const [lockSizes, setLockSizes] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleLockSizeSelection = size => {
    setLock({ ...lock, size });
  };

  useEffect(() => {
    const handleLockSizes = async () => {
      const response = await getLocksPerSize(lock.controller.id);
      if (response.status === 200) {
        setLockSizes(response.data.locks);
      } else {
        toastRef.current.handleOpen('Erro ao recuperar portas.', 'error');
      }
      setLoading(false);
    };
    handleLockSizes();
  }, [lock]);

  const handleDelivery = async () => {
    await remoteDelivery(
      lock.size,
      lock.controller.id,
      lock.habitation.id,
      lock.habitation.ex_id,
      lock.habitation.ex_uuid,
    )
      .then(res => {
        if (res.data.status === 200) {
          resetStep();
        } else {
          toastRef.current.handleOpen(
            res.data.message || 'Erro ao realizar entrega.',
            'error',
          );
        }
      })
      .catch(() => {
        toastRef.current.handleOpen('Erro ao realizar entrega.', 'error');
      });
  };

  return (
    <>
      <Toast ref={toastRef} />

      <div className={classes.header} onClick={stepBackward}>
        <GoBackButton />
      </div>

      <div className={classes.title}>
        <Title text="Selecione o bloco/rua" bold />
      </div>

      {loading ? (
        <Loading />
      ) : Object.keys(lockSizes).length ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={1}
          className={classes.grid}
        >
          {Object.entries(lockSizes).map(([key, value]) => (
            <Grid item key={key}>
              <DefaultButton
                onClick={() => handleLockSizeSelection(key.toUpperCase())}
                selected={key === lock.size}
                sx={{ fontSize: '1rem' }}
                disabled={!value.amount}
              >
                <div className={classes.sizeButton}>
                  <span
                    className={classes.sizeText}
                  >{`Tamanho ${key.toUpperCase()}`}</span>
                  <span
                    className={classes.sizeText}
                  >{`Disponíveis: ${value.amount}`}</span>
                  <span className={classes.sizeText}>{`Dimensão: ${
                    value.dimension || '-'
                  }`}</span>
                </div>
              </DefaultButton>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Title
          text="Nenhum locker encontrado :("
          fontStyle="italic"
          paddingTop="15px"
          fontSize="1.2rem"
          light
        />
      )}

      <div className={classes.card}>
        <LockCard lock={lock} />
      </div>

      <DefaultButton
        onClick={handleDelivery}
        disabled={!lock.size}
        color={!lock.size ? 'secondary' : 'primary'}
        sx={{ fontSize: '1.3rem' }}
      >
        Confirmar dados
      </DefaultButton>
    </>
  );
}
