export const normalizeControllers = controllers => {
  const normalizedControllers = controllers.map(controller =>
    controller.controller_name.substr(0, 4) === 'Mini'
      ? {
          ...controller,
          controller_name: controller.controller_name.substr(
            10,
            controller.controller_name.length,
          ),
        }
      : controller,
  );

  return normalizedControllers;
};
